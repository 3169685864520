@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./variables";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

@layer base {
  :root {
    @each $property, $color in $base-colors-normal {
      #{$property}: $color;
    }
  }

  .dark {
    @each $property, $color in $base-colors-dark {
      #{$property}: $color;
    }
  }

  * {
    @apply m-0 p-0 box-border;
  }

  body {
    @apply bg-background text-foreground;
  }

  // #region Scroll
  /* width */
  ::-webkit-scrollbar {
    @apply w-2;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-inherit rounded-lg;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-primary rounded-lg;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-primary;
  }

  .react-datepicker {
    font-size: 16px; /* Thay đổi kích thước font của cả picker */
  }
}