.react-datepicker {
    width: 310px;
}

.react-datepicker__month-text{
    width: 94px !important;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    &.react-datepicker__month-text--selected {
        background: red !important;
        color: white !important;
    }
    &.react-datepicker__month-text--today {
        background: #F4F4F4;
        color: black;
    }
}
.react-datepicker__navigation{
    margin-top: 1px;
}
